<template>
    <div v-cloak>
        <!-- TOOLBOX -->
        <h1><i class="fas fa-table"></i>
            {{ pageTitle}}
        </h1>

    <div id="accordion">

      <div class="card">
        <div class="card-header alert-info p-0" id="headingOne">
            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <b>
                    1.Volba dat
                </b>
            </button>
            <div class="badge bg1 border m-1 p-2" v-if="cars.length>0">{{ cars.length }} vozidel</div>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
          <div class="card-body">
             <slot name="filter"></slot>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header alert-info p-0" id="headingFilter">
            <div class="float-right">
                <a href="#" @click.prevent="invertSelect" title="Vybere nevybraná data pro případné porovnání" class="badge badge-warning my-3 mx-1 border">Invertovat</a>
                <a class="badge badge-warning my-3 mx-1 border" href="#" @click.prevent="deleteFilters" title="Zašktrá filtry a vybere všechna data ">Zrušit</a>
            </div>

            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseFilter" aria-expanded="true" aria-controls="collapseFilter">
                <b>
                    2.Filtry {{  this.filter_vals }}
                </b>
            </button>

            <div class="badge bg1 border m-1 p-2">Vybráno {{ selectedCount }} ze {{ cars.length }}</div>

        </div>

        <div id="collapseFilter" class="collapse p-1" aria-labelledby="headingFilter" data-parent="#accordion">
          <div class="card-body p-0">
            <div class="row no-gutters">

                <div v-for="filter in filters" :class="filter.class" :key="'f'+filter.id">
                    <div class="px-1 m-1 border">
                        <div class="float-right">


                            <span class="badge badge-pill badge-warning border">
                                {{ filter_vals[filter.id].length }}/{{ filter.items.length }}
                            </span>
                        </div>

                        <a class="btn btn-sm" href="#" @click.prevent="selectAllItems(filter.id)" v-bind:class="[
                                {'btn-warning': filter.items.length>filter_vals[filter.id].length, 'btn-light':filter.items.length==filter_vals[filter.id].length}
                        ]" title="Vybrat vše">
                            <i class="far fa-check-square"></i>
                        </a>


                        <a class="btn btn-sm" href="#" @click.prevent="unTickAllFilterItems(filter.id)"  v-bind:class="[
                                {'btn-warning': filter_vals[filter.id].length>0, 'btn-light':filter_vals[filter.id].length==0}
                        ]" title="Zrušit vše">
                           <i class="far fa-square"></i>
                        </a>

                        <a href="#" @click.prevent="invertSelect(filter.id)" title="Zaškrtne nezaškrtnuté filtry a naopak" class="btn btn-sm"  v-bind:class="[
                                {'btn-warning': filter.items.length>filter_vals[filter.id].length, 'btn-light':filter.items.length==filter_vals[filter.id].length}
                        ]">
                            <i class="far fa-minus-square"></i>
                        </a>



                        <a data-toggle="collapse" :href="'#collapse_' + filter.id" role="button" aria-expanded="false" :aria-controls="'collapse_' + filter.id" class="my-3">
                            <b>
                                {{ filter.title }}
                            </b>
                        </a>

                        <div class="p-2 collapse" :id="'collapse_' + filter.id">
                            <div v-for="item in filter.items" v-bind:key="item" class="d-inline-block pr-3">
                                <input
                                    type="checkbox"
                                    v-model="filter_vals[filter.id]"
                                    :checked="filter_vals[filter.id].indexOf(item)>-1"
                                    :value="item" @change="checkboxChange(item)"
                                    />

                                    <template v-if="filter_selected_counts[filter.id + item]===undefined">
                                        <a href="#" @click.prevent="unTickBut(filter.id, item, false)"><del class="text-dark">{{ item }}</del></a>
                                    </template>
                                    <template v-else>
                                        <a href="#" @click.prevent="unTickBut(filter.id, item, false)">{{ item }}</a>
                                    </template>

                                    <sup class="badge badge-light badge-pill border"><a href="#" @click.prevent="unTickBut(filter.id, item, true)">{{ filter_counts[filter.id + item] }}</a> <span class="text-dark" v-if="filter_selected_counts[filter.id + item]!==undefined && filter_selected_counts[filter.id + item]!=filter_counts[filter.id + item]">/{{ filter_selected_counts[filter.id + item] }}</span></sup>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

          </div> <!-- /CARD BODY -->
        </div>
      </div>

      <div class="card">
        <div class="card-header p-0 alert-info" id="headingTwo">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <b>
                    3.Výběr dle parametrů
                </b>
            </button>
            <div class="badge bg1 border m-1 p-2">Vybráno {{ selectedCount }} ze {{ cars.length }}</div>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
          <div class="card-body">

                <div class="d-inline-block">

                    <button title="Vybrat všechny" class="btn btn-secondary btn-sm" @click.prevent="selectAllVue"  v-show="selectedCount<cars.length">
                            <i class="far fa-check-square"></i>
                    </button>

                    <button title="Zrušit výběr" class="btn btn-secondary btn-sm" @click.prevent="selectNoneVue" v-show="selectedCount>0">
                            <i class="far fa-square"></i>
                    </button>

                    <button title="Invertovat výběr" class="btn btn-secondary btn-sm" @click.prevent="invertSelect"><i class="far fa-minus-square"></i></button>

                </div>
                <div class="d-inline-block">
                    <nobr>
                    <span @click.prevent ="switchSelect">
                        <i class="fa pl-2" :class="{'fa-check-square':select,'fa-square':!select}" aria-hidden="true"></i>
                        {{ select?'Označit':'Odznačit' }}
                    </span>
                    dle hodnoty
                    <select v-model="numericFilter">
                        <option v-for="(item,id) in numericFilters" v-bind:value="item.id" :key="id">
                            {{ item.title }}
                        </option>
                    </select>
                        <a href="#" :title="select?'Vybrat':'Odebrat' +' vozy s nižší hodnotou než'" class="btn btn-secondary btn-sm" @click.prevent="selectNumeric(true)"><i class="fas fa-sort-amount-down"></i></a>
                         <input type="number" class="custom" value='500000' style="width:5rem;" v-model="numericValue">
                        <a href="#" :title="select?'Vybrat':'Odebrat' + ' vozy s vyšší hodnotou než'" class="btn btn-secondary btn-sm" @click.prevent="selectNumeric(false)"><i class="fas fa-sort-amount-up"></i></a>


                    </nobr>
                </div>
          </div>
        </div>
      </div>



      <div class="card">
        <div class="card-header alert-info p-0" id="headingData">
            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseData" aria-expanded="false" aria-controls="collapseData">
                <b>
                    4.Jednotlivá data
                </b>
            </button>
        </div>

        <div id="collapseData" class="collapse" aria-labelledby="headingData" data-parent="#accordion">
          <div class="card-body">





        <!-- DATA TABLE START   *****************************************************************    -->
                    <div class="table-responsive">

                                <table class="simple-table" width="100%" cellspacing="0">
                                  <thead>
                                    <tr>
                                      <th width="50"></th>
                                      <th width="10%" class="text-center">SPZ / VIN</th>
                                      <th width="15%" class="text-center">Vůz</th>
                                    <th title="Lokalita" width="5%" class="text-right" v-if="offs>1"  @click.prevent="orderBy('office_id')" :class="{'text-info':this.orderColumn=='office_id'}">
                                        Lok
                                        <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>

                                    </th>
                                    <th :title="statTitle" width="6%" class="text-right" v-if="offs>1"  @click.prevent="orderBy(statColumn)" :class="{'text-info':this.orderColumn==statColumn}">
                                        {{ statTitle }}
                                        <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>

                                    </th>

                                      <th width="5%" @click.prevent="orderBy('dni_sklad')" :class="{'text-info':this.orderColumn=='dni_sklad'}"
                                      title="Počet dní na skladě od přivezení vozidla">
                                        Na skladě
                                        <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                      </th>

                                      <th width="5%" class="text-right" @click.prevent="orderBy('dni_inzerce')" :class="{'text-info':this.orderColumn=='dni_inzerce'}">
                                            Dní inzerce <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                      </th>

                                      <th width="5%" class="text-right" @click.prevent="orderBy('car_margin')" :class="{'text-info':this.orderColumn=='car_margin'}">
                                            Marže
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                      </th>

                                      <th width="5%" class="text-right" @click.prevent="orderBy('margin_percent')" :class="{'text-info':this.orderColumn=='margin_percent'}">
                                            % marže
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                      </th>

                                      <th width="9%" class="text-right" @click.prevent="orderBy('cost_price')" :class="{'text-info':this.orderColumn=='cost_price'}">
                                            Pořizovací cena
                                        <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>

                                      </th>
                                      <th width="9%" class="text-right"  :class="{'text-info':this.orderColumn=='car_price'}">
                                            <span @click.prevent="orderBy('car_price')" >Prodejní cena
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                            </span>
                                      </th>
                                      <th width="10%" class="text-right"  :class="{'text-info':this.orderColumn=='car_discount'}">
                                        <span @click.prevent="orderBy('car_discount')">Akční sleva
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true" ></i>
                                        </span>
                                      </th>

                                      <th width="10%" class="text-right" :class="{'text-info':this.orderColumn=='car_bonus'}">
                                            <span @click.prevent="orderBy('car_bonus')">Akční bonus
                                            <i class="fas text-info" :class="{'fa-sort-amount-down':!asc, 'fa-sort-amount-up':asc}" aria-hidden="true"></i>
                                            </span>
                                      </th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(car) in carsSelected" :key="'X' + car.id" >
                                            <td>
                                                <center>
                                                    <input type= "checkbox"  name="car_checkbox" @change="selected(car.id)"  :v-model="car.selected" :checked="car.selected">
                                                </center>
                                            </td>

                                            <td>
                                                <nobr>
                                                    <center>
                                                        <nobr>{{ formatSpz(car.spz) }}</nobr>
                                                    </center>
                                                    <small>{{ car.vin }}</small>
                                                </nobr>
                                            </td>

                                            <td align="left">
                                                <a :href="'/cars/'+car.id" target="_new">
                                                {{ car.title }}
                                                </a>
                                            </td>

                                            <td v-if="offs>1">
                                                {{ car.office_abbr }}
                                            </td>

                                            <td>
                                                {{ car[statColumn] }}
                                            </td>

                                            <td>
                                                {{ car.dni_sklad }}
                                            </td>

                                            <td>
                                                {{ car.dni_inzerce }}
                                            </td>
                                            <td>
                                                {{ formatCurrency(car.car_margin) }}
                                            </td>
                                            <td>
                                                {{ formatNumber(car.margin_percent,1) }}
                                            </td>

                                            <td>
                                                {{ formatCurrency(car.cost_price) }}
                                            </td>

                                            <td>
                                                    {{ formatCurrency(car.car_price) }}
                                            </td>

                                            <td>
                                                {{ formatCurrency(car.car_discount) }}
                                            </td>

                                            <td>
                                                {{ car.car_bonus }}

                                            </td>



                                    </tr>
                                  </tbody>
                              </table>

                </div>  <!-- DATA TABLE END   *****************************************************************    -->


          </div>
        </div>
      </div>



</div> <!-- ACCORDITION -->

        <!-- ZOBRAZ SEZNAM VOZIDEL   *****************************************************************    -->


<!-- left:0;right:0; top:0;bottom:0%;max-width:95%;max-height:95%;z-index:10000;background-color:silver;border:2px black solid;   display: flex;justify-content: center; overflow:scroll; -->



        <div v-if="displayCars" style="position:relative; z-index:1005;" class="shadow">

                <div style="position: absolute;left: 50%; top:50%;transform: translate(-50%, -20%);    width: 95%; max-height:60rem; overflow:auto;border:1px #696b6e solid;border-radius:5px;box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">
            <div style=" text-align: center; border: 3px solid white; border-radius:5px; padding:1rem; background-color:#f2fcf3;">

                    <div style="display:flex">
                        <div style="flex-grow:2;">
                            <h3>Seznam vozidel</h3>

                        </div>
                        <div>
                            <button @click="clearDisplayCars">Zavřít</button>
                        </div>

                    </div>

                    <rentals-table-component :carsSelected="getCarsToDisplay()">

                    </rentals-table-component>

                </div>
            </div>

        </div>



        <div class="my-2">


                        <select v-model="statType">
                            <option v-for="(item,id) in statPivots" v-bind:value="item.id" :key="item.id">
                                {{ item.title }}
                            </option>
                        </select>


                        <select v-model="countColumn">
                            <option v-for="(item,key) in statFuns" v-bind:value="item.id" :key="key">
                                {{ item.title }}
                            </option>
                        </select>

        </div>

        <table-component :statPivots="statPivots" :statFuns="statFuns" :stat="stat" :pivots="pivots" stattitle=""  :historyindex="null" :selectedColumn="countColumn" :hideHistoryGraphs="false" @displayCarsEvent="handleDisplayCars">
        </table-component>

        <div>
            <a class="btn btn-sm btn-primary" @click.prevent="storeCurrentValuesIntoHistory">Uložit tabulku jako</a>
            <input type="text" v-model="tablename"  class="inputTableName">
            <span class="text-info" v-if="history.length===0">Uložení tabulky s možností porovnání a zobrazení hodnot v grafu</span>
                            <div class="float-right m-1" v-if="history.length>0">
                                <small>
                                    Skrýt uložené grafy <input type= "checkbox"  v-model="hideHistoryGraphs">
                                </small>
                            </div>


        </div>

                <table-component v-for="(h,key) in history" :key="key" :statPivots="statPivots" :statFuns="statFuns" :stat="h.stat" :pivots="h.pivots" :stattitle="h.title" :historyindex="key" @delHistoryEvent="delHistory" :selectedColumn="countColumn" :hideHistoryGraphs="hideHistoryGraphs"  @displayCarsEvent="handleDisplayCars">
                </table-component>

        <graph-component :statPivots="statPivots" :statFuns="statFuns" :history="history" :selectedColumn="countColumn"></graph-component>


    </div>
</template>



<script>
import RentalsTableComponent from './RentalsTableComponent.vue';
    export default {
  components: { RentalsTableComponent },

      props: {
        action:String,
        carset:Array,
        resultlog:Object,
        allowedoffices:Array,
        stattitle:String, //titulek ke statistice

       },
      data: function() {
        return {
                filter_vals:[],
                filter_counts:[],
                filter_selected_counts:[],
                filters:[

                    {
                        id:"office", // = DB field values
                        title:"Lokalita",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-4",
                    }, //filter

                    {
                        id:"team", // = DB field values
                        title:"Tým",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-4",
                    }, //filter

                    {
                        id:"typ_zakaznika", // = DB field values
                        title:"Zákazník",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-4",
                    }, //filter


                    {
                        id:"brand_title", // = DB field values
                        title:"Značka",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-12",
                    }, //filter

                    {
                        id:"model_title", // = DB field values
                        title:"Model",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-12",
                    }, //filter

                    {
                        id:"model_group", // = DB field values
                        title:"Model - skupina",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-12",
                    }, //filter

                    {
                        id:"origin_title", // = DB field values
                        title:"Původ",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-12",
                    }, //filter


                    {
                        id:"period", // = DB field values
                        title:"Měsíc",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-12",
                    }, //filter




                    {
                        id:"category_title", // = DB field values
                        title:"Rámec",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-4",
                    }, //filter


                    {
                        id:"fuel_title", // = DB field values
                        title:"Palivo",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-4",
                    }, //filter

                    {
                        id:"year_sold", // = DB field values
                        title:"Rok",
                        name:"",
                        items:[],
                        order:true, //order by title
                        class:"col-md-4",
                    }, //filter

                ], //end filters collection

            localStorageVars:[  //co všechno uložit do local storage
                'history',
            ],

            selectedModels:[],
            pageTitle:"",
            hideHistoryGraphs:true,
            prefix:"stat_",
            localStorageVars:[  //co všechno uložit do local storage
                // 'filter_vals',
                'history',
                'statType',
                'type',
                'average',
                'expectation',
                'asc',
                'orderColumn',
                'orderDir',
                'numericFilterType',
            ],
            tablename:"",
            lsSaved:false,
            saving:false, //zda ukládá
            type: 0, //co měnit
            loading:true,
            types:[
                {id:0, title: "prodejní cena", column:"car_price", plusOnly:false},
                {id:1, title: "sleva z ceny", column:"car_discount", plusOnly:true},
                {id:2, title: "bonus prodejce", column:"car_bonus", plusOnly:true},
            ],

            changeJSON:"",  //všechny změny k odeslání na server

            numericFilter: 1, //co měnit
            numericValue:0,
            numericAddOnly:0,
            numericRemoveOnly:1,
            numericFilters:[
                {id:0, title: "pořizovací cena", column:"car_price"},
                {id:1, title: "doba na skladě", column:"dni_sklad"},
                {id:2, title: "akční sleva", column:"car_discount"},
                {id:3, title: "marže", column:"car_margin"},
            ],


            history:[
                // {
                //     title:'Statistika XYZ',
                //     stat:{}, //0 = aktuální stav, 1 = změny
                //     pivots:[], //seznam všech pivotů
                //     statType:0,
                //     average:false, // zda je přepnuto na průměrnou cenu
                // }
            ],

            //statistika
            stat:{}, //0 = aktuální stav, 1 = změny
            pivots:[], //seznam všech pivotů
            statType:2,
            countColumn:'marign_sum',
            statFuns:[  //projede všechny funkce jak pro navržené změny tak pro aktuální stav
                {id:'count',
                    no_sum:false,
                    no_average:true,
                    decimals:0,
                    title:'Počet',
                    agregate:false,
                    formatFun:(num)=>{ return this.formatCurrency(num);},
                    desc:'počet vozidel odpovídající výběru a zvolené kategorii',
                    fun:function(car, change) { return change?0:1;}
                }, //fun: vrádí hodnotu co se má připočítat , pokud change=true


                {id:'rental_revenue',
                    title:'Tržby půjčovna',
                    agregate:false,
                    no_average:false,
                    decimals:0,
                    no_sum:false,
                    desc:'Tržby za vozidlo v půjčovně bez DPH.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_revenue'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat


                {id:'rental_cost',
                    title:'Náklady půjčovna',
                    agregate:false,
                    no_average:false,
                    no_sum:false,
                    decimals:0,
                    desc:'Náklady za vozidlo v půjčovně bez DPH.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_cost'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat


                {id:'total_profit',
                    title:'Zisk půjčovna',
                    agregate:false,
                    no_average:false,
                    no_sum:false,
                    decimals:0,
                    desc:'Hrubý zisk za vozidlo v půjčovně. DPH.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['total_profit'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat

                {id:'consolidated_profit',
                    title:'Konsolidovaný zisk',
                    no_average:false,
                    no_sum:false,
                    agregate:false,
                    decimals:0,
                    desc:'Celkový zisk od prvotního nákupu po prodej',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['consolidated_profit'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat



                {id:'rental_sale_price',
                    title:'Prodejní cena',
                    agregate:false,
                    no_average:false,
                    no_sum:false,
                    decimals:0,
                    desc:'Realizovaná prodejní cena na vozidle.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_sale_price'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat



/*

                {id:'rental_revenue_exp',
                    title:'Oček. Tržby půjčovna',
                    no_average:false,
                    no_sum:false,
                    desc:'Očekávaný Tržby za vozidlo v půjčovně bez DPH.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_revenue_exp'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat


                {id:'rental_cost_exp',
                    title:'Oček. Náklady půjčovna',
                    no_average:false,
                    no_sum:false,
                    desc :'Očekávané náklady za vozidlo v půjčovně bez DPH.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_cost_exp'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat


                {id:'total_profit_exp',
                    title:'Oček. zisk půjčovna',
                    no_average:false,
                    no_sum:false,
                    desc:'Očekávaný Hrubý zisk za vozidlo v půjčovně. DPH.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['total_profit_exp'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat

                {id:'consolidated_profit_exp',
                    title:'Oček. celkový zisk',
                    no_average:false,
                    no_sum:false,
                    desc :'Očekávaný celkový zisk od prvotního nákupu po prodej',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['consolidated_profit_exp'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat



                {id:'rental_sale_price_exp',
                    title:'Oček. prodejní cena',
                    no_average:false,
                    no_sum:false,
                    desc :'Očekávaná prodejní cena na vozidle.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_sale_price_exp'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat


*/



                {id:'rental_revenue_ratio',
                    title:'Poměr Tržby půjčovna',
                    agregate:false,
                    no_average:false,
                    no_sum:true,
                    decimals:0,
                    desc:'Poměr očekávaných a reálných tržeb za vozidlo v půjčovně bez DPH.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_revenue_ratio'];
                    },

                    /* A G R E G A T E
                    agregate:true, //měl by kalkulovat až agregované výsledky
                    agregateFun: function(data, change) {   //fun: vrádí hodnotu co se má připočítat
                        //console.log('margin_percent: ', data['marign_sum'], data['price_sum'], Math.round((data['marign_sum']*1.21)/data['price_sum']*10000)/100);
                        return Math.round((data['marign_sum']*1.21)/data['price_sum']*10000)/100;
                    },
                    */


                }, //fun: vrádí hodnotu co se má připočítat


                {id:'rental_cost_ratio',
                    title:'% náklady půjčovna',
                    agregate:false,
                    no_average:false,
                    no_sum:true,
                    decimals:0,
                    desc :'Poměr očekávaných a reálných nákladů za vozidlo v půjčovně bez DPH.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_cost_ratio'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat


                {id:'total_profit_ratio',
                    title:'Poměr zisk půjčovna',
                    agregate:false,
                    no_average:false,
                    no_sum:true,
                    decimals:0,
                    desc:'Poměr očekávaného a reálného hrubého zisku za vozidlo v půjčovně. DPH.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['total_profit_ratio'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat

                {id:'consolidated_profit_ratio',
                    title:'Poměr konsolidovaný zisk',
                    agregate:false,
                    no_average:false,
                    no_sum:true,
                    decimals:0,
                    desc :'Poměr očekávaného a reálného hrubého zisku od prvotního nákupu po prodej',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['consolidated_profit_ratio'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat



                {id:'rental_sale_price_ratio',
                    title:'Poměr prodejní cena',
                    agregate:false,
                    no_average:false,
                    no_sum:true,
                    decimals:0,
                    desc :'Očekávaná prodejní cena na vozidle.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_sale_price_ratio'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat



                {id:'rental_purchase_price',
                    title:'Nákup',
                    agregate:false,
                    no_average:false,
                    no_sum:false,
                    decimals:0,
                    desc:'Nákupní cena vozidla bez DPH.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_purchase_price'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat



                {id:'rental_purchase_margin',
                    title:'Marže nákup',
                    agregate:false,
                    no_average:false,
                    no_sum:true,
                    decimals:0,
                    desc:'Marže na vozidle při nákupu.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_purchase_margin'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat



                {id:'rental_sale_margin',
                    title:'Marže prodej',
                    agregate:false,
                    no_average:false,
                    no_sum:false,
                    decimals:0,
                    desc:'Marže na vozidle při prodeji.',
                    formatFun:(num)=>{ return this.formatCurrency(num);},

                    fun:function(car, change) {
                        return car['rental_sale_margin'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat

                //**********  BONUSY  - vyjmuto v rentalu */
                // {
                //     id:'bonus_sum',
                //     title:'Bonusy',
                //     no_average:false,
                //     no_sum:false,
                //     desc:'součet nároku na bonusy pro prodejce',
                //     formatFun:(num)=>{ return this.formatCurrency(num);},

                //     fun:function(car, change) {
                //         //změna znamená rozdíl oproti původnímu

                //         return car['car_bonus'];
                //     },
                // }, //fun: vrádí hodnotu co se má připočítat



                {id:'sale_days',
                    title:'Prodej dní',
                    agregate:false,
                    no_average:false,
                    no_sum:true,
                    decimals:0,
                    desc:'Počet dní v prodeji.',

                    formatFun:(num)=>{ return this.formatNumber(num,0);},
                    fun:function(car, change) {
                        return car['sale_days'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat


                {id:'rental_days',
                    title:'V půjčovně - dní',
                    agregate:false,
                    no_average:false,
                    no_sum:true,
                    decimals:0,
                    desc:'Počet dní, kdy bylo vozidlo aktivní v půjčovně.',

                    formatFun:(num)=>{ return this.formatNumber(num,0);},
                    fun:function(car, change) {
                        return car['rental_days'];
                    },
                }, //fun: vrádí hodnotu co se má připočítat



            ],

            statPivots:[
                {
                    id:0,
                    title: "Původ",
                    pivotColumn:"origin_group",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"origin_group",  //umožní mít jiný pivot než title
                },


                {
                    id:1,
                    title: "Model (skupina)",
                    pivotColumn:"model_group",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"model_group",  //umožní mít jiný pivot než title
                },

                {
                    id:2,
                    title: "Lokalita",
                    pivotColumn:"office",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"office",  //umožní mít jiný pivot než title
                },


                {
                    id:3,
                    title: "Tým",
                    pivotColumn:"team",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"team",  //umožní mít jiný pivot než title
                },


                {
                    id:4,
                    title: "Sklad",
                    pivotColumn:"sklad",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"sklad",  //umožní mít jiný pivot než title
                },

                {
                    id:5,
                    title: "Model (detail)",
                    pivotColumn:"model_title",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"model_title",  //umožní mít jiný pivot než title
                },

                {
                    id:6,
                    title: "Cena vozidla",
                    pivotColumn:"cena_vozidla",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"cena_vozidla",  //umožní mít jiný pivot než title
                },


                {
                    id:7,
                    title: "Měsíc",
                    pivotColumn:"period",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"period",  //umožní mít jiný pivot než title
                },


                {
                    id:8,
                    title: "Palivo",
                    pivotColumn:"fuel_title",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"fuel_title",  //umožní mít jiný pivot než title
                },


                {
                    id:9,
                    title: "Rámec",
                    pivotColumn:"category_title",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"category_title",  //umožní mít jiný pivot než title
                },


                {
                    id:10,
                    title: "Obsah motoru",
                    pivotColumn:"motor_size_title",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"motor_size_title",  //umožní mít jiný pivot než title
                },

                {
                    id:11,
                    title: "Barva",
                    pivotColumn:"color_title",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"color_title",  //umožní mít jiný pivot než title
                },



                {
                    id:12,
                    title: "Výkon",
                    pivotColumn:"hp",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"hp",  //umožní mít jiný pivot než title
                },



                {
                    id:13,
                    title: "Zákazník",
                    pivotColumn:"typ_zakaznika",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"typ_zakaznika",  //umožní mít jiný pivot než title
                },


                {
                    id:14,
                    title: "Původ (detail)",
                    pivotColumn:"origin_title",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"origin_title",  //umožní mít jiný pivot než title
                },


                {
                    id:15,
                    title: "Rok",
                    pivotColumn:"deal_year",
                    pivotId:(value)=>{return value?value.replace(/[^a-zA-Z0-9]/g, ''):"empty";}, //konverze ID bez paznaků
                    pivotTitleColumn:"deal_year",  //umožní mít jiný pivot než title
                },


                //další id je 16 !!!


            ],

            show:false,

            asc:true, //pořadí řazení tabulky
            orderColumn:'',
            orderDir: false,
            cars:[], //vozidla
            log:[], //chyby po uložení
            offices:[], //seznam povolených kanceláří
            carsToBeDisplayed:[],
            select:1,   //označ = 1 či odznač = 0
            numericFilterType:1,
            changeReferenceType:0,
            changeValue:0,
            showLog:true,
            changeReference:[
                { id:0, title: "Kč", column: "", fun: (val, car)=>{ return val;}},
                { id:1, title: "% z pořizovací ceny", column: "cost_price", fun: (val, car)=>{ return Math.round(car.cost_price*val/100);}},
                { id:2, title: "% z prodejní ceny", column: "car_price", fun: (val, car)=>{ return Math.round(car.car_price*val/100);}},
                { id:3, title: "% z marže", column: "car_margin", fun: function(val, car){ return Math.round(car.car_margin*val/100);}},
                { id:4, title: "% z bonusu", column: "car_bonus", fun: (val, car)=>{ return Math.round(car.car_bonus*val/100);}},
                { id:5, title: "% ze slevy", column: "car_discount", fun: (val, car)=>{ return Math.round(car.car_discount*val/100);}},
            ],

        }
      },

      computed: {
        selectedCount: function() {
            var count = 0;
            for(var i = 0; i < this.cars.length; ++i){
                if(this.cars[i].selected)
                    count++;
            }
            return count;
        },

        carsSelected: function() {
            return this.cars.filter((item)=>{
                return item.selected;
            });
        },

        displayCars: function() {
            //console.log('ZOBRAZIT?',this.carsToBeDisplayed.length>0);
            return this.carsToBeDisplayed.length>0;
        },

        typeTitle: function(){
            return this.types[this.type].title;
        },
        typeColumn: function(){
            return this.types[this.type].column;
        },

        statTitle: function(){
            return this.statPivots[this.statType].title;
        },

        statColumn: function(){
            return this.statPivots[this.statType].pivotColumn;
        },


        statPivotFun: function(){
            return this.statPivots[this.statType].pivotId;
        },


        plusOnly: function(){
            return this.types[this.type].plusOnly;
        },

        numericFilterTitle: function(){
            return this.numericFilters[this.numericFilter].title;
        },
        numericFilterColumn: function(){
            return this.numericFilters[this.numericFilter].column;
        },

        changeColumn: function(){
            return this.changeReference[this.changeReferenceType].column;
        },
        changeFunction: function(){
            return this.changeReference[this.changeReferenceType].fun;
        },






        offs:function(){
            if (this.offices) {
                return this.offices.length;
            } else {
                return 0;
            }
        },


      },
      methods: {

        //vyfiltruje všechna vozidla a vybere ta, co se mají zobrazit
        getCarsToDisplay: function(){
            if (this.carsToBeDisplayed.length==0) {return;}

            //projde všechyn vozy a vybere které jsou v this.carsToBeDisplayed
            let cars = [];

            this.cars.forEach(car=>{
                if (this.carsToBeDisplayed.indexOf(car.id)!==-1) {
                    cars.push(car);
                }
            });
            //console.log('FOUND CARS', cars);
            return cars;

        },

        //jakmile přijde event z table component se seznamem vozidel -> zobrazí je
        handleDisplayCars: function(cars) {
            this.carsToBeDisplayed = cars;
        },

        //jakmile přijde event z table component se seznamem vozidel -> zobrazí je
        clearDisplayCars: function() {
            this.carsToBeDisplayed = [];
        },


        //vymaže všechny položky filtrů
        clearFilterItems: function(name) {
            if (name>"") {
                if (this.filters[name]) {
                    this.filter[name].items=[];
                }
            } else {
                this.filters.forEach(filter => {
                    filter.items = [];
                });
            }
        },

        //zaškrtne všechny položky filrů
        tickAllFilterItems: function(name) {
            if (name>"") {
                this.filters.forEach(filter =>{
                    if (filter.id==name) {
                        this.filter_vals[name] = filter.items;
                    }
                });
            } else {
                this.filters.forEach(filter => {
                    this.filter_vals[filter.id] = filter.items;

                });
            }
            this.selectCars();
        },

        //zaškrtne všechny položky filrů
        unTickAllFilterItems: function(name) {
            if (name>"") {
                this.filters.forEach(filter =>{
                    if (filter.id==name) {
                        this.filter_vals[name] = [];
                    }
                });
            } else {
                this.filters.forEach(filter => {
                    this.filter_vals[filter.id] = [];

                });
            }
            this.selectCars();
        },


        //smaže položku z historie
        delHistory:function(index) {
            this.history.splice(index, 1);

            //uloží do local storage
            this.storeValue('history', this.prefix);
        },
        //uloží tabulku do historie
        storeCurrentValuesIntoHistory:function() {
            this.history.push({
                    title:this.tablename,
                    stat:{...this.stat}, //0 = aktuální stav, 1 = změny
                    pivots:{...this.pivots}, //seznam všech pivotů
                    statType:this.statType,
                    average:this.average, // zda je přepnuto na průměrnou cenu
                    expectation:this.expectation, // zda je přepnuto na průměrnou cenu
            });

            //uloží do local storage
            this.storeValue('history', this.prefix);
        },

        //nastaví nazev tabulky dle posledního zvoleného filtru
        filterSetTableName: function(val) {
            if (val>"") {
                this.tablename =  val;
            } else {
                this.tablename = this.stattitle;
            }
        },

        //uloží filtry do local storage
        storeFilters: function () {
            //this.storeValue('filter_vals',this.prefix);
        },

        findFilter: function(filterId) {
            for (const filter of this.filters) {
                if (filterId===filter.id) {
                    return filter;
                }
            }
        },

        findStatFun: function(funId) {
            for (const fun of this.statFuns) {
                if (funId===fun.id) {
                    return fun;
                }
            }
        },

        unTickBut: function (name, item, resetOther) {
            if (resetOther) {
                this.selectAllItems();
            }
            this.filterSetTableName(item);
            this.storeFilters();
            this.filters.forEach(filter =>{
                    if (filter.id==name) {
                        this.filter_vals[name] = [item];
                    }
                });
            this.selectCars();
        },

        selectAllItems: function(name){
            this.tickAllFilterItems(name);
        },

        deselectAllItems: function(name){
            this.clearFilterItems(name);
        },

        sortFilterItems: function(name) {
            //seřadí všechny  filtry
            this.filters.forEach( filter => {
                filter.items.sort();
            });
        },




        addCarToFilter: function (car) {

            this.filters.forEach(filter => {

                if (filter.items.length===0 || filter.items.indexOf(car[filter.id])===-1)  {
                    // console.log('OPTION -> '+ car[filterColumn] + '    -  '+car.title);
                    // console.log(car[filter['optionObject']]);
                    filter.items.push(car[filter.id]);

                    //počet záznamů odpovídajících filtru


                }

                if (this.filter_counts[filter.id + car[filter.id]]) {
                    this.filter_counts[filter.id + car[filter.id]] = this.filter_counts[filter.id + car[filter.id]] + 1;
                } else {
                    this.filter_counts[filter.id + car[filter.id]] = 1;
                }

            });
        },


        //načte filtry
        loadFilters: function(selected = false) {
            //vynuluje
                this.clearFilterItems();
            //pro každý záznam
            this.cars.forEach(car => {
                if (!selected || car.selected===1) {
                    this.addCarToFilter(car);
                }
            });
            //seřadí
            this.sortFilterItems();
            this.tickAllFilterItems();
        },


        //zavolá vždy po zašktnutí checkboxu filtr
        checkboxChange: function (val) {
            this.filterSetTableName(val);
            this.storeFilters();
            this.selectCars();
        },

        //projde jednotlivá data a určí, zda jsou SELECTED
        selectCars: function() {
            this.filter_selected_counts = [];
            //     console.log('filter_selected_counts BEFORE');
            //     console.log(this.filter_selected_counts);
            this.cars.forEach(car=>{
                let select = 1;
                this.filters.forEach(filter=>{
                    //pokud neprojde pak select dá na false
                    if (this.filter_vals[filter.id].indexOf(car[filter.id])===-1) {
                        select = 0;

                    }
                    //  else {
                    //     //napočítá počty
                    //     if (this.filter_selected_counts[filter.id + car[filter.id]]) {
                    //         this.filter_selected_counts[filter.id + car[filter.id]] = this.filter_selected_counts[filter.id + car[filter.id]] + 1;
                    //     } else {
                    //         this.filter_selected_counts[filter.id + car[filter.id]] = 1;
                    //     }
                    // }
                });
                car.selected = select;

                //projde jednotlivá data a napočítá filtry pro SELECTED
                if (car.selected===1) {
                    this.filters.forEach(filter=>{
                        if (this.filter_selected_counts[filter.id + car[filter.id]]) {
                            this.filter_selected_counts[filter.id + car[filter.id]] = this.filter_selected_counts[filter.id + car[filter.id]] + 1;
                        } else {
                            this.filter_selected_counts[filter.id + car[filter.id]] = 1;
                        }
                    });
                }
            });

                // console.log('filter_selected_counts');
                // console.log(this.filter_selected_counts);

        },


        //projde jednotlivá data a napočítá filtry pro SELECTED
        calculateSelectFilters: function() {
            this.filter_selected_counts = [];
            this.cars.forEach(car=>{
                if (car.selected===1) {
                    this.filters.forEach(filter=>{
                        if (this.filter_selected_counts[filter.id + car[filter.id]]) {
                            this.filter_selected_counts[filter.id + car[filter.id]] = this.filter_selected_counts[filter.id + car[filter.id]] + 1;
                        } else {
                            this.filter_selected_counts[filter.id + car[filter.id]] = 1;
                        }
                    });
                }
            });

                // console.log('filter_selected_counts');
                // console.log(this.filter_selected_counts);

        },


         isLocalStorage:function (){
            var test = 'test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
            } catch(e) {
                return false;
            }
        },


        switchSelect: function() {
            if (!this.select) {
                this.select = 1;
            } else {
                this.select = 0;
            }
        },
        //seřadí celý set
        orderBy:function(column){
            // alert('ORDER '+column);
            this.orderColumn = column;
            this.orderDir = this.asc;
            this.asc = !this.asc;
            this.cars.sort((a,b)=>(this.asc?1:-1)*((+a[column]>+b[column])?1:-1));

        },
        selectNumeric:function(lower){
            var col = this.numericFilterColumn;
            var val = this.numericValue*1;
            this.cars.forEach((car)=>{

                //nezabývat se s položkami, které nejsou vybrané pokud pracovat jen nad výběrem
                if (car.selected==1) {
                    if (
                        (lower && (car[col]*1<=val))
                        ||
                        (!lower && (car[col]*1>=val) )
                        ) {
                        //vyhovuje podmínce
                            car.selected = this.select?1:0;  //označ nebo odznač

                    } else {
                        //nevyhovuje podmínce
                            car.selected = this.select?0:1; //označ nebo odznač
                    }
                }

                    // if (this.numericRemoveOnly!=1) {
                    //  car.selected = 1;
                    // }
                    // if (this.numericAddOnly!=1) {
                        // car.selected = 0;
                    // }
                    // if (this.numericRemoveOnly==1) {
                    //  car.selected = 0;
                    // }
            });
        },
        calculate: function(){
            //jakou položku
            var col = this.typeColumn;
            var val = this.changeValue;
            var fun = this.changeFunction;
            var plusOnly = this.changePlusOnly;
            this.cars.forEach((car)=>{
                if (car.selected) {
                    // console.log(fun(val,car) + '=> '+col + '_change');
                    var r = +fun(val,car);
                    //toto je hodnota o kterou se má referenční sloupec (TYPE) změnit !!! přičíst
                    //pouze kladné hodnoty
                    // if (!this.plusOnly || r>0) {
                        car[col + '_change'] = +car[col] + r;
                    // }
                }
            })
            this.calcStat();
            this.changeJSON = this.transformChanges();

        },
        showLine: function (carId) {
            return true;
        },

        emptyStat: function() { //resetuje statistiku v objektu
            var s={};
            this.statFuns.forEach((fun,index)=>{
                s[fun.id] = 0;
            });
            return s;
        },
        resetStat:function(){ //resetuje počítadla před počítáním (calcStat)
            //součty
            for (var i = 0; i < 2; i++) {
                this.stat[i] = this.emptyStat();
            }
            this.pivots = {};
        },
        calcStat:function (type) { //napočítá hodnoty pro selected - 0 = regulerní, 1 = změna
            var col = this.statColumn; //sloupec, kolem kterého se pivotuje
            var pivotIdFun = this.statPivotFun;
            // statType
            this.resetStat();

            //napočítání statistiky pro jednotlivé hodnoty kde je agregate = false
            this.cars.forEach((car)=>{
                if (car.selected) {

                        this.statFuns.forEach((func,index)=>{
                            if(func.agregate==false) {

                                var val = Math.round(func.fun(car, false),0);
                                var valChange = Math.round(func.fun(car, true),0);
                                //total sums
                                this.stat[0][func.id] = this.stat[0][func.id] + val;
                                this.stat[1][func.id] = this.stat[1][func.id] + valChange;
                                //pivot sums
                                var pivotValue = pivotIdFun(car[col]); //hodnota pivotu
                                //pokud neexistuje - resetuj
                                if (typeof this.stat[0][pivotValue] === 'undefined') {
                                this.stat[0][pivotValue] = this.emptyStat();
                                this.stat[1][pivotValue] = this.emptyStat();
                                this.pivots[pivotValue] = car[col];
                                this.stat[0][pivotValue]['cars'] = [];
                                }
                                this.stat[0][pivotValue][func.id] = this.stat[0][pivotValue][func.id] + val;
                                this.stat[1][pivotValue][func.id] = this.stat[1][pivotValue][func.id] + valChange;
                                //přidej seznam vozidel
                                if(this.stat[0][pivotValue]['cars'].indexOf(car.id) === -1) {
                                    this.stat[0][pivotValue]['cars'].push(car.id);
                                }

                            }

                        });
                }
            });

            //
            //console.log(this.pivots, 'Pivoti:');
            this.statFuns.forEach((func,index)=>{
                            if(func.agregate==true) {
                                //vezme již uložené hodnoty předá je do funkce a výsledek uloží na pivot
                                // napočítá agregovanou funkci napříč všem pivots
                                Object.keys(this.pivots).forEach((pivot) => {
                                    this.stat[0][pivot][func.id] = func.agregateFun(this.stat[0][pivot], false);
                                    this.stat[1][pivot][func.id] = func.agregateFun(this.stat[1][pivot], true);
                                });

                                //udělat celkovou agregovanou sumu , volá opět funkci pro výpočet agregátu
                                this.stat[0][func.id] = func.agregateFun(this.stat[0], false);
                                this.stat[1][func.id] = func.agregateFun(this.stat[1], true);

                            }

                        });


            //seřazení pivota
            //console.log(this.stat);
            const ordered = {};
            Object.keys(this.pivots).sort().forEach((key)=>{
              ordered[key] = this.pivots[key];
            });
            this.pivots = ordered;
            // console.log(this.pivots);
        },


        sendChanges:function() {  //odesle do databaze zmenový požadavek...
        },

        selected: function (carId) {
            this.cars.forEach((car, id)=>{
                if (car.id == carId) {
                    if (car.selected) {
                        car.selected=0;
                    } else {
                        car.selected=1;
                    }
                }
            });
        },

        updateField: function (event, type, carId) {
            this.cars.forEach((car, id)=>{
                if (car.id == carId) {
                    // console.log(this.typeColumn + '_change');
                    car[this.typeColumn + '_change'] = +event.target.value;
                    this.calcStat();
                    this.changeJSON = this.transformChanges();
                }
            });
        },

        toggleType: function () {

            if (this.type>=2) {
                this.type = 0;
            } else {
                this.type ++;
            }
        },
        selectAllVue: function(){
            this.cars.forEach(car=>{
                car.selected = 1;
            });
            this.switchSelect();
            this.tickAllFilterItems();
        },

        selectNoneVue: function(){
            this.cars.forEach(car=>{
                car.selected = 0;
            });
            this.switchSelect();
            this.unTickAllFilterItems();

        },

        // provede inverzi - buď filtru nebo všech filtrů
        invertSelect: function(filter){
            // console.log(this.filter_vals);
            // console.log(filter);
            const filterObject = this.findFilter(filter);
//            console.log(filterObject);

            if (filterObject) {
                // console.log('INVERT');
                this.filter_vals[filter]= filterObject.items.filter(x => !this.filter_vals[filter].includes(x));
                // console.log(this.filter_vals[filter]);

                this.selectCars();
                this.calculateSelectFilters();

            } else {
                this.cars.forEach(car=>{
                    car.selected = (car.selected==1)?0:1;
                });
                this.calculateSelectFilters();
            }
        },

        //zaškrtne všechny filtry
        deleteFilters:function() {
            this.tickAllFilterItems();
            this.calculateSelectFilters();
        },

        formatDate: function (date) {


            var date = new Date (date);
              var day = date.getDay();
              var month = date.getMonth();
              var year = date.getFullYear();

            return day + '.' + month + '.' + year;
        },


      }, // METHODS


      created:function(){

        this.cars = this.carset; //vozidla
        this.log = this.resultlog; //výsledek uložení
        this.offices = this.allowedoffices;
        this.tablename = this.stattitle;
        this.pageTitle = this.stattitle;

        //načíst filtry
        this.loadFilters();
        // console.log('filter_vals');
        // console.log(this.filter_vals);

        this.localStorageRestore(this.localStorageVars, 'stat_');
        // this.restoreValue('history', this.prefix);
        // console.log('filter_vals');
        // console.log(this.filter_vals);

        //přepočítat statistiku
        this.calcStat();

      }, //CREATED

      mounted:function(){
      }, //MOUNTED

      watch:{

        carsToBeDisplayed:function(){
            //pokud je null - hide
            //pokud obsahuje elementy - display
            // console.log('WATCH', this.carsToBeDisplayed);


        },


        selectedCount: function() {
            this.calcStat();
        },

        statType:function() {
            this.storeValue('statType', this.prefix);
            this.calcStat();
        },

        countColumn:function() {
            this.storeValue('countColumn', this.prefix);
        },


      }, //WATCH


    }
</script>
<style>
    .inputTableName {
        width:25rem;
    }
</style>
